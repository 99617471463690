<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-center">
      <v-col>
        <v-row v-if="loading">
          <v-col class="mt-5 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <p class="body font-weight-thin">Chargement...</p>
          </v-col>
        </v-row>
        <v-row v-else-if="!result && !loading" class="align-center">
          <v-col class="fill-height">
            <v-scroll-y-transition mode="out-in">
              <div class="text-center">
                <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg"
                max-width="200"></v-img>
                <p class="display-1 font-weight-thin grey--text text--lighten-1">
                  Aucune transcription n'a été chargée
                </p>
                <v-btn small class="mt-4" color="teal" outlined :to="url('upload/text')">
                  <v-icon small>mdi-upload</v-icon> Uploader un fichier
                </v-btn>
              </div>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="3">
            <p class="title">Analyse dialogique
              <!-- <v-chip large color="red" dark right>
                <v-icon large>mdi-alert-circle</v-icon> Démo sauf pour Q/R
              </v-chip> -->
            </p>
            <p class="subitle">Paramètres</p>
            <div>
              <v-switch
                v-model="options.qa"
                label="Afficher les questions/réponses">
              </v-switch>
              <v-switch label="Afficher les transactions"></v-switch>
              <v-switch label="Afficher les backchannels"></v-switch>
            </div>
            <p class="subitle">Résumé</p>
            <LabelledNumber label="Nombre backchannels" :value="18"/>
            <LabelledNumber label="Backchannels/transaction" :value="0.32"/>
            <LabelledNumber label="Nombre de questions" :value="stats.questions"/>
            <LabelledNumber label="Nombre de transactions" :value="10"/>
          </v-col>
          <v-col>
            <v-chip small color="blue lighten-2 mb-4" label>
              <!-- <v-icon left small color="blue">
                mdi-comment
              </v-icon> -->
              Nouvelle transaction</v-chip>
          <p class="body" v-for="(item,i) in result" :key="i">
            <span class="font-weight-bold">
              {{ item.participant }} -
              <v-chip v-show="options.qa && item.question"
                small color="teal lighten-4" pill>Question
              </v-chip>
              <v-chip v-show="options.qa && item.answer"
                small color="red lighten-4" pill>Réponse
              </v-chip>
              <v-chip v-show="!((1+i)%9)" small color="purple lighten-4" pill>Backchannels</v-chip>
            </span>

            {{ item.speech }}

            <span class="d-block mt-4">
            <v-chip v-if="!((i)%9)" small color="blue lighten-4" label>
              <!-- <v-icon left small color="blue">
                mdi-comment-remove
              </v-icon> -->
              Fin de transaction</v-chip>
              <v-divider v-if="!((i)%9)"></v-divider>
            <v-chip v-if="!((i)%9)" small color="blue lighten-2" label>
              <!-- <v-icon left small color="blue">
                mdi-comment
              </v-icon> -->
              Nouvelle transaction</v-chip>
            </span>
          </p>
        </v-col>
        <v-col cols="3">

        </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import LabelledNumber from '@/components/common/LabelledNumber.vue';

export default {
  name: 'InterviewToolDialogic',
  mixins: [MixinPatients, MixinInterviews],
  components: { LabelledNumber },
  props: {
    patientId: String,
    interviewId: String,
  },
  methods: {
    url(path) {
      return `/patient/${this.patientId}/interview/${this.interviewId}/${path}`;
    },
  },
  async mounted() {
    this.loading = true;
    this.result = await this.getDialogicAnalysis(this.patientId, this.interviewId);
    this.stats.questions = this.result.filter((x) => x.question).length;
    this.loading = false;
  },
  data: () => ({
    result: null,
    loading: false,
    options: {
      qa: true,
    },

    stats: {
      questions: 0,
    },
  }),
};
</script>
