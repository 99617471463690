import MixinCommon from './common';

export default {
  mixins: [MixinCommon],

  data: () => ({
    gender: [
      { value: 'Féminin', id: 1 },
      { value: 'Masculin', id: 2 },
      { value: 'Neutre', id: 3 },
    ],
    path: [
      { value: 'CAP, BEP', id: 1 },
      { value: 'Bac - Baccalauréat', id: 2 },
      { value: 'Bac+2 - DEUG, BTS, DUT, DEUST', id: 3 },
      { value: 'Bac+3 - Licence, licence professionnelle', id: 4 },
      { value: 'Bac+4 - Maîtrise, master 1', id: 5 },
      { value: 'Bac+5 - Master, diplôme d\'études approfondies, diplôme d\'études supérieures spécialisées, diplôme d\'ingénieur', id: 6 },
      { value: 'Bac+8 - Doctorat, habilitation à diriger des recherches', id: 7 },
      { value: 'Dispenses de titres - Mère ou père de 3 enfants, Sportif de haut niveau', id: 8 },
    ],
    lifestyle: [
      { value: 'Célibataire', id: 1 },
      { value: 'En couple', id: 2 },
      { value: 'Fiancé(e)', id: 3 },
      { value: 'Marié(e)', id: 4 },
      { value: 'En union civile', id: 5 },
      { value: 'Divorcé(e)', id: 6 },
      { value: 'Veuf/veuve', id: 7 },
    ],
    drugs: [
      { value: 'Alcool', id: 1 },
      { value: 'Cigarettes', id: 2 },
      { value: 'Cocaïne et crack', id: 3 },
      { value: 'Héroïne', id: 4 },
      { value: 'Amphétamines', id: 5 },
      { value: 'MDMA/ecstasy', id: 6 },
      { value: 'Plants de cannabis', id: 7 },
      { value: 'Résine de cannabis', id: 8 },
      { value: 'Herbe de cannabis', id: 9 },
      { value: 'Autres substances', id: 10 },
    ],
    livingPlace: [
      { value: 'Seul', id: 1 },
      { value: 'Avec un(e) conjoint(e)', id: 2 },
      { value: 'Chez les parents', id: 4 },
      { value: 'Chez les tuteurs', id: 5 },
      { value: 'Chez les grands-parents', id: 6 },
      { value: 'Chez un(e) oncle/tante', id: 7 },
      { value: 'Chez un ami', id: 8 },
      { value: 'S.D.F', id: 9 },
    ],
    colors: [
      '#00B0FF',
      '#00BFA6',
      '#F50057',
      '#536DFE',
      '#F9A826',
      '#2F7D00',
    ],

    defaultRadarOptions: {
      colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
      chart: {
        animations: { enabled: false },
        type: 'radar',
      },
      dataLabels: {
        enabled: true,
      },
      title: { text: 'Profil patient/psy', align: 'left' },
      xaxis: {
        categories: [
          'Données brutes',
          'Variété lexicale',
          'Densité lexicale',
          'Structure verbale',
          'Arbre syntaxique',
          'Relations syntaxiques',
          'Subordination',
        ],
      },
    },
  }),

  methods: {

    // Get patient info from server
    async getPatientInfo(id) {
      const response = await this.get(`${this.apiRoot}/patient/${id}`);
      const data = await response.json();

      if (response.status !== 200) {
        this.showAlertMessage('error', data);
        return null;
      }
      return data;
    },

    // Add a new patient
    async createPatient(data) {
      const response = await this.post(`${this.apiRoot}/patient`, JSON.stringify(data));

      if (!response.ok) {
        this.showAlertMessage('error', await response.text());
        return null;
      }

      const patient = await response.json();

      return patient;
    },

    // Update patient information
    async updatePatient(id, data) {
      const response = await this.put(`${this.apiRoot}/patient/${id}`, JSON.stringify(data));

      if (response.ok) {
        // BUG: message non affiche -> pas d'events a travers les slots
        this.sendNotification('Les informations ont été mises à jour');
      } else {
        this.showAlertMessage('error', await response.text());
      }
    },

    // Delete patient by ID
    async deletePatient(id) {
      const response = await this.delete(`${this.apiRoot}/patient/${id}`);

      if (!response.ok && !response.status === 404) {
        this.showAlertMessage('error', await response.text());
      }
    },

    // Return current user's patient list
    async getPatientList() {
      const response = await this.get(`${this.apiRoot}/patients`);
      const patients = await response.json();

      return patients;
    },

    // Get patient info from server
    async getPatientHistory(id, start, end) {
      const response = await this.get(`${this.apiRoot}/patient/${id}/history?start=${start}&end=${end}`);
      const data = await response.json();

      if (response.status === 404) {
        return { history: null };
      }

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return { history: null };
      }

      return data.payload;
    },

    // Get patient info from server
    async getPatientSummary(id) {
      const response = await this.get(`${this.apiRoot}/patient/${id}/summary`);
      const data = await response.json();

      if (response.status === 404) {
        return { history: null };
      }

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return { history: null };
      }

      return data.payload;
    },

    // Return date in the right format
    convertDate(date) {
      if (date) {
        return new Date(date).toLocaleString('fr-FR').split(' ')[0];
      }
      return '';
    },
  },
};
