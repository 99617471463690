<template>
  <v-list-item two-line >
    <v-list-item-content>
      <div class="text-center">
        <span class="overline">{{ label }}</span>
          <p class="display-1 font-weight-thin">
            {{ value }}
          </p>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'LabelledNumber',
  props: {
    label: String,
    value: Number,
  },
};
</script>
