import MixinCommon from './common';

export default {
  mixins: [MixinCommon],
  methods: {
    // Get interview info from server
    async getInterviewInfo(patientId, id) {
      const response = await this.get(`${this.apiRoot}/patient/${patientId}/interview/${id}`);
      const data = await response.json();

      if (response.status !== 200) {
        const error = await response.text();
        this.sendNotification(error);
        return null;
      }

      return data;
    },

    async getDialogicAnalysis(patientId, id) {
      const response = await this.get(`${this.apiRoot}/patient/${patientId}/interview/${id}/tool/dialogic`);

      if (response.status !== 200) {
        return null;
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return null;
      }

      return data.payload;
    },

    async getProsodyAnalysis(patientId, id, params) {
      const paramsStr = `start=${params.start}&end=${params.end}&limit=${params.limit}&avgwin=${params.avgWin}`;
      const url = `${this.apiRoot}/patient/${patientId}/interview/${id}/tool/prosody?${paramsStr}`;
      const response = await this.get(url);

      if (response.status !== 200) {
        return null;
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return null;
      }

      return data.payload;
    },

    // Get interview info content
    async getInterviewTranscription(patientId, id, model) {
      const response = await this.get(`${this.apiRoot}/patient/${patientId}/interview/${id}/tool/speech-to-text?model=${model}`);

      if (response.status !== 200) {
        const err = await response.text();
        this.sendNotification(`Erreur lors de la transcrition: ${err}`);
        return '';
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return [];
      }

      this.sendNotification('Transcription enregistrée!');

      return data.payload;
    },

    async getReport(patientId, id, type, options) {
      const response = await this.post(`${this.apiRoot}/patient/${patientId}/interview/${id}/report/${type}`, JSON.stringify({ options }));

      if (response.status !== 200) {
        return null;
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return null;
      }

      return data.payload;
    },

    // Get similarities in interview content
    async getLexicalSimilarity(patientId, id, param) {
      const response = await this.get(`${this.apiRoot}/patient/${patientId}/interview/${id}/tool/similarity?r=${param.radius}&m=${param.method}`);

      if (response.status !== 200) {
        const error = await response.text();
        this.sendNotification(error);
        return [];
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return [];
      }

      return data.payload;
    },

    // Add a new interview
    async createInterview(id, data) {
      const response = await this.post(
        `${this.apiRoot}/patient/${id}/interview`,
        JSON.stringify(data),
      );

      if (!response.ok) {
        this.showAlertMessage('error', await response.text());
        return null;
      }

      const interview = await response.json();

      return interview;
    },

    // Update Interview information
    async updateInterview(id, patient, data) {
      const response = await this.put(`${this.apiRoot}/patient/${patient}/interview/${id}`,
        JSON.stringify(data));

      if (response.ok) {
        this.$router.go(-1);
      } else {
        this.showAlertMessage('error', await response.text());
      }
    },

    // Delete interview by ID
    async deleteInterview(id, patient) {
      const response = await this.delete(`${this.apiRoot}/patient/${patient}/interview/${id}`);

      if (!response.ok && response.status !== 404) {
        const error = await response.text();
        this.sendNotification(error);
      }
    },

    // Delete interview by ID
    async deleteMedia(id, patient, media) {
      const url = `${this.apiRoot}/patient/${patient}/interview/${id}/?media=${media}`;
      const response = await this.delete(url);

      if (!response.ok && response.status !== 404) {
        const error = await response.text();
        this.sendNotification(error);
      }
    },

    async countInterviews(id) {
      // Retrieve number of interviews for this patient
      const interviews = await this.get(`${this.apiRoot}/patient/${id}/interviews`);
      const interviewArray = await interviews.json();

      return interviewArray.length;
    },

    async getInterviewsList(patientId) {
      const response = await this.get(`${this.apiRoot}/patient/${patientId}/interviews`);
      const interviews = await response.json();

      return interviews;
    },

    async getSVGFile(patientId, interviewId, conll) {
      const response = await this.post(`${this.apiRoot}/patient/${patientId}/interview/${interviewId}/tool/tree`, JSON.stringify(conll));
      const svg = await response.text();

      return svg;
    },

    async uploadInterview(patientId, interviewId, file, type, language) {
      // WARNING: Do not remove these headers. Default headers use application/json,
      // which cannot be used with file upload.
      const headers = {
        'Client-Application-Version': '1.0',
        'Client-Application-Name': 'slodim',
      };

      const url = `${this.apiRoot}/patient/${patientId}/interview/${interviewId}/upload/${type}?lang=${language}`;

      return this.post(url, file, headers);
    },

    async uploadCorpus(patientId, files, type, language) {
      // WARNING: Do not remove these headers. Default headers use application/json,
      // which cannot be used with file upload.
      const headers = {
        'Client-Application-Version': '1.0',
        'Client-Application-Name': 'slodim',
      };

      const responses = files.map(async (item) => {
        const interview = await this.createInterview(patientId, { name: item.name });

        // eslint-disable-next-line
        const url = `${this.apiRoot}/patient/${patientId}/interview/${interview._id}/upload/${type}?lang=${language}`;

        const data = new FormData();
        data.append('files', item);

        const response = await this.post(url, data, headers);
        return { file: item.name, ok: response.ok, msg: await response.text() || '' };
      });

      const response = await Promise.all(responses);
      return response;
    },

    async getAnalysisResult(patientId, interviewId, options = []) {
      const url = `${this.apiRoot}/patient/${patientId}/interview/${interviewId}/tool/analysis`;
      const query = options.length ? `?options=${JSON.stringify(options)}` : '';
      const response = await this.get(url + query);

      if (!response.ok) {
        const error = await response.text();
        this.sendNotification(error);
        return { unavailable: true };
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return { unavailable: true };
      }

      return data.payload;
    },

    async getMultiAnalysis(normalized, interviews, options = []) {
      const url = `${this.apiRoot}/tool/transversal?normalized=${normalized ? 'yes' : 'no'}`;
      const query = options.length ? `&options=${JSON.stringify(options)}` : '';
      const response = await this.post(url + query, JSON.stringify({ interviews }));

      if (!response.ok) {
        const error = await response.text();
        this.sendNotification(error);
        return null;
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return null;
      }

      return data.payload;
    },

    async getInterviewsHeatmap(interviews, mask, factors, reference, options = []) {
      const url = `${this.apiRoot}/tool/transversal/interviews-heatmap?normalized=no`;
      const query = options.length ? `&options=${JSON.stringify(options)}` : '';

      const response = await this.post(url + query, JSON.stringify({
        interviews,
        mask,
        factors,
        reference,
      }));

      if (!response.ok) {
        const error = await response.text();
        this.sendNotification(error);
        return null;
      }

      const data = await response.json();

      if (!data.ok) {
        this.sendNotification(data.exception.stack);
        return null;
      }

      return data.payload;
    },

    async getSignificanceScore(data) {
      const url = `${this.apiRoot}/tool/transversal/significance`;

      const response = await this.post(url, JSON.stringify(data));

      if (!response.ok) {
        const error = await response.text();
        this.sendNotification(error);
        return null;
      }

      const result = await response.json();

      if (!result.ok) {
        this.sendNotification(result.exception.stack);
        return null;
      }

      return result.payload;
    },
  },
};
